import React from "react";

const CloseSVG = () => {
  return (
    <svg
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.353553"
        y1="0.646447"
        x2="34.3535"
        y2="34.6464"
        stroke="#FF0000"
      />
      <line
        x1="34.3586"
        y1="0.348393"
        x2="0.35864"
        y2="35.3484"
        stroke="#FF0000"
      />
    </svg>
  );
};

export default CloseSVG;
