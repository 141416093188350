import React, { useEffect, useState } from "react";
import { graphql, navigate, PageRenderer, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Modal from "react-modal";
import * as styles from "./PostModal.module.scss";
import Markdown from "react-markdown";
import CloseSVG from "./CloseSVG";
import clsx from "clsx";
import philipsImage from "../images/phillips.gif";

Modal.setAppElement(`#___gatsby`);

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(255, 255, 255, 0.95)",
    zIndex: 30,
  },
};

const PostModal = ({ data }) => {
  // PageRenderer stuff.
  const building = typeof window === "undefined";
  const [indexPageData, setIndexPageData] = useState(
    !building && window.indexPageData
  );
  useEffect(() => {
    window.setIndexPageData = () => {
      setIndexPageData(window.indexPageData);
    };
  }, []);

  const [modalOpen, setModalOpen] = useState(true);
  const modalCloseTimeout = 300;
  const closeModal = () => {
    setModalOpen(false);
    setTimeout(() => navigate(`/`), modalCloseTimeout);
  };

  const pageData = data?.file?.childMarkdownRemark?.frontmatter;
  const postSlug = pageData.slug;
  return (
    <div>
      <PageRenderer
        key={"/"}
        location={{ pathname: "/" }}
        pageResources={indexPageData}
        path="/"
      />
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        className={styles.reactModal}
        contentLabel="Modal"
        style={modalStyles}
        closeTimeoutMS={modalCloseTimeout}
      >
        <Link className={styles.close} to="/">
          <CloseSVG />
        </Link>
        <div className={styles.image}>
          {postSlug === "/frankie-and-oscar-phillips" ? (
            <img src={philipsImage} alt={pageData.title} />
          ) : (
            <GatsbyImage image={getImage(pageData.image)} />
          )}
        </div>
        <div
          className={clsx(
            styles.content,
            pageData.content_interview && styles.contentInterview
          )}
        >
          <p className={styles.talentName}>
            {postSlug === "/janice-emms-jordan-nobbs-chris-lockwood"
              ? "Janice Emms, Chris Lockwood"
              : pageData.title}
          </p>
          {pageData.words && pageData.words.length > 1 && (
            <p>Words: {pageData.words}</p>
          )}
          <Markdown>{pageData.content}</Markdown>
        </div>
      </Modal>
    </div>
  );
};

export const query = graphql`
  query pageModalQuery($id: String) {
    file(id: { eq: $id }) {
      id
      childMarkdownRemark {
        frontmatter {
          title
          slug
          content
          words
          content_interview
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;

export default PostModal;
